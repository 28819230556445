<template>
  <div>
    <div>
      <h3>Generate Functional Requirements from BT</h3>
      <b-row>
        <b-col cols="6">
          <h5>Defaults</h5>
          <b-form-group label="Select Enablers:" label-for="enabler-select">
            <b-form-select
              id="enabler-select"
              v-model="value.selected_enablers"
              :options="performer_opts"
              :select-size="4"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <h5>Restrict</h5>
          <b-form-group label="Only Generate Requirements for the following Enablers:" label-for="enabler-restrict">
            <b-form-select
              id="enabler-restrict"
              v-model="value.allowed_enablers"
              :options="performer_opts"
              :select-size="4"
              multiple
            />
            <small class="w-100">All allocated enablers will be used if none selected</small>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <specifications-form-select v-model="value.specification" label="Select Target Specification:" @input="specChange" />
    <h5>Select where to generate requirements...</h5>
    <b-table
      :fields="table_fields"
      :items="target_spec_requirements.requirements"
      sticky-header
      responsive
      selectable
      select-mode="single"
      style="max-height: 60vh"
      @row-selected="rowSelected"
    >
      <template v-slot:cell(object_text)="data">
        <span v-html="data.value" />
      </template>
    </b-table>
    <hr>
    <h5>Select Placement</h5>
    <b-form-group v-slot="{ ariaDescribedby }">
      <b-form-radio v-model="value.direction" :aria-describedby="ariaDescribedby" name="some-radios" value="before">
        Before
      </b-form-radio>
      <b-form-radio v-model="value.direction" :aria-describedby="ariaDescribedby" name="some-radios" value="after">
        After
      </b-form-radio>
      <b-form-radio v-model="value.direction" :aria-describedby="ariaDescribedby" name="some-radios" value="child">
        Child
      </b-form-radio>
    </b-form-group>
    <h3 style="text-align: center">
      <span v-if="value.dest_target && type === 'functional'">
        <strong>Action:</strong> Generate requirements for {{ component.name }} {{ value.direction }} {{ value.dest_target_disp }}
      </span>
      <span v-else>
        Select Requirement For Placement
      </span>
    </h3>

  </div>
</template>
<script>
import { mapState } from 'vuex'
import SpecificationsFormSelect from '@/components/Specifications/Forms/SelectOld.vue'

export default {
  name: 'GenerateBTRequirementForm',
  components: {
    SpecificationsFormSelect,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    component: {
      type: Object,
      default: null,
    },
    iface: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: 'functional',
    },
  },
  computed: {
    ...mapState({
      performers: state => state.domainModel.performers,
      specifications: state => state.specifications.specifications,
      target_spec_requirements: state => state.requirements.specificationRequirements,
    }),
    performer_opts() {
      return this.performers.map(x => ({ value: x.id, text: x.qualified_name }))
    },
    spec_opts() {
      return this.specifications.map(x => ({ value: x.id, text: x.qualified_name }))
    },
  },
  mounted() {
    this.$store.dispatch('specifications/fetchSpecificationsSimple')
  },
  methods: {
    rowSelected(rows) {
      if (rows[0] && rows[0].id) {
        this.$emit('input', { ...this.value, ...{ dest_target: rows[0].id, dest_target_disp: rows[0].display_id } })
      }
    },
    specChange(spec) {
      const vueApp = this
      console.debug('Spec selected:', spec)
      vueApp.$store.dispatch('requirements/getSpecificationRequirements', spec)
        .then(() => {
          console.debug('Spec requirements: ', vueApp.target_spec_requirements)
        })
    },
  },
  data() {
    return {
      table_fields: [
        {
          key: 'section',
          label: 'Section',
        },
        {
          key: 'display_id',
          label: 'ID',
        },
        {
          key: 'object_text',
          label: 'Requirement',
        },
        {
          key: 'priority',
          label: 'Priority',
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>
